import {withStyles} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import {withNamespaces} from 'react-i18next';
import {compose} from 'redux';

function DataTableHeader(props) {
    const {t, order, orderBy, onRequestSort, columns} = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {columns.map(
                    column => (
                        <TableCell
                            key={column.id}
                            align={column.numeric ? 'right' : 'left'}
                            sortDirection={orderBy === column.id ? order : false}
                        >
                            <Tooltip
                                title={t('sort')}
                                placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                enterDelay={300}
                            >
                                <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={order}
                                    onClick={createSortHandler(column.id)}
                                >
                                    <strong>{column.label}</strong>
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    ),
                    this,
                )}
            </TableRow>
        </TableHead>
    );
}

DataTableHeader.propTypes = {
    columns: PropTypes.array.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string,
    orderBy: PropTypes.string
};

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3
    },
    header: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    clickable: {
        cursor: 'pointer'
    }
});

function DataTable(props) {
    const {
        t,
        classes,
        columns,
        data,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        page,
        setPage,
        pageSize,
        setPageSize,
        totalElements,
        customFormatter,
        rowClick,
        rowDoubleClick,
    } = props;

    let clicks = [];
    let timeout;

    function handleRowClick(event, row) {
        event.preventDefault();
        clicks.push(new Date().getTime());
        window.clearTimeout(timeout);
        timeout = window.setTimeout(() => {
            if (clicks.length > 1 && clicks[clicks.length - 1] - clicks[clicks.length - 2] < 250) {
                rowDoubleClick && rowDoubleClick(row);
            } else {
                rowClick && rowClick(row);
            }
        }, 250);
    }

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }


    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setPageSize(event.target.value);
    }

    function applyFormatter(row) {
        if (customFormatter) {
            return customFormatter(row);
        }
        return row;
    }

    const emptyRows = pageSize - data.length;
    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table>
                    <DataTableHeader
                        t={t}
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        columns={columns}
                    />
                    <TableBody>
                        {
                            data.map((row, rowIndex) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={rowIndex}
                                            onClick={rowClick || rowDoubleClick ? (event) => handleRowClick(event, row) : null}
                                            className={rowClick || rowDoubleClick ? classes.clickable : null}
                                        >
                                            {Object.values(columns).map((column, index) => {
                                                return (
                                                    <TableCell
                                                        key={index}
                                                        align={column.numeric ? 'right' : 'left'}>
                                                        {applyFormatter(row)[column.id]}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        {emptyRows > 0 && (
                            <TableRow style={{height: 48 * emptyRows}}>
                                <TableCell colSpan={columns.length}/>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage={t('rows-per-page')}
                component="div"
                count={totalElements}
                rowsPerPage={pageSize}
                page={page}
                backIconButtonProps={{
                    'aria-label': t('previous-page'),
                }}
                nextIconButtonProps={{
                    'aria-label': t('next-page'),
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

DataTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    order: PropTypes.string,
    setOrder: PropTypes.func.isRequired,
    orderBy: PropTypes.string,
    setOrderBy: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    setPageSize: PropTypes.func.isRequired,
    totalElements: PropTypes.number.isRequired,
    customFormatter: PropTypes.func,
    rowClick: PropTypes.func,
    rowDoubleClick: PropTypes.func
};

export default compose(
    withNamespaces('data_table'),
    withStyles(styles)
)(DataTable);
import defaults from '../constants/defaults';

export const defaultInitialState = {
    data: [],
    page: {
        size: defaults.defaultPageSize,
        totalElements: 0,
        totalPages: 0,
        number: defaults.defaultPage,
    },
    sort: {},

};

export default function createDataTableReducer(dataTableName = '') {
    return function reducer(state = defaultInitialState, action) {
        switch (action.type) {
            case `DT_${dataTableName}_PAGE`:
                return {
                    ...state,
                    page: {
                        ...state.page,
                        ...action.page
                    }
                };
            case `DT_${dataTableName}_SORT`:
                return {
                    ...state,
                    sort: {
                        ...state.sort,
                        ...action.sort
                    },
                    page: {
                        ...state.page,
                        number: defaults.defaultPage
                    }
                };
            case `DT_${dataTableName}_FILTER`:
                return {
                    ...state,
                    filter: {
                        ...action.filter
                    },
                    page: {
                        ...state.page,
                        number: defaults.defaultPage
                    }
                };
            case `DT_${dataTableName}_FETCHED`:
                return {
                    ...state,
                    data: action.payload.content,
                    page: {
                        size: action.payload.size,
                        totalElements: action.payload.totalElements,
                        totalPages: action.payload.totalPages,
                        number: action.payload.number,
                    }
                };
            case `DT_${dataTableName}_FILTERS`:
                return {
                    ...state,
                    permanentFilter: action.permanentFilter,
                    filter: action.filter
                };
            case `DT_${dataTableName}_URL_AND_FILTER`:
                return {
                    ...state,
                    urlVariables: action.urlVariables,
                    filter: action.filter
                };
            default:
                return state;
        }
    };
}
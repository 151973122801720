import defaults from '../constants/defaults';
import {getRestClient} from '../utils/restClient';
import {generateBaseUrl} from '../utils/urlUtils';

export default function createDataTableActions(dataTableName, reducer, apiUrl = '') {

    function _setOrder(order) {
        return (dispatch) => {
            dispatch({
                type: `DT_${dataTableName}_SORT`,
                sort: {
                    order
                }
            });
        };
    }

    function _setOrderBy(orderBy) {
        return (dispatch) => {
            dispatch({
                type: `DT_${dataTableName}_SORT`,
                sort: {
                    orderBy
                }
            });
            dispatch(_fetchData());
        };
    }

    function _setPage(page) {
        return (dispatch) => {
            dispatch({
                type: `DT_${dataTableName}_PAGE`,
                page: {
                    number: page
                }
            });
            dispatch(_fetchData());
        };
    }

    function _setPageSize(pageSize) {
        return (dispatch) => {
            dispatch({
                type: `DT_${dataTableName}_PAGE`,
                page: {
                    size: pageSize
                }
            });
            dispatch(_fetchData());
        };
    }

    function _setFilter(filter) {
        return (dispatch) => {
            dispatch({
                type: `DT_${dataTableName}_FILTER`,
                filter: {
                    ...filter
                }
            });
            dispatch(_fetchData());
        };
    }

    function _setFilters(permanentFilter, filter) {
        return (dispatch) => {
            dispatch({
                type: `DT_${dataTableName}_FILTERS`,
                permanentFilter: permanentFilter,
                filter: filter
            });
            dispatch(_fetchData());
        };
    }

    function _setUrlVarialbesAndFilter(urlVariables, filter) {
        return (dispatch) => {
            dispatch({
                type: `DT_${dataTableName}_URL_AND_FILTER`,
                urlVariables: urlVariables,
                filter: filter
            });
            dispatch(_fetchData());
        };
    }

    function _fetchData() {
        return (dispatch, getState) => {
            const state = getState()[reducer];
            return getRestClient(dispatch)
                .get(buildUrlWithQuery(generateBaseUrl(apiUrl, state.urlVariables), {
                    ...buildFilteringParams(state.permanentFilter),
                    ...buildPagingParams(state.page),
                    ...buildSortingParams(state.sort),
                    ...buildFilteringParams(state.filter)
                }))
                .then(response => dispatch(fetchSuccess(response.data)))
                .catch(error => {
                    throw(error);
                });
        };
    }

    function fetchSuccess(payload) {
        return {
            type: `DT_${dataTableName}_FETCHED`,
            payload
        };
    }

    return {
        setOrder: (order) => _setOrder(order),
        setOrderBy: (orderBy) => _setOrderBy(orderBy),
        setPage: (page = defaults.defaultPage) => _setPage(page),
        setPageSize: (pageSize = defaults.defaultPageSize) => _setPageSize(pageSize),
        setFilter: (filter) => _setFilter(filter),
        init: () => _fetchData(),
        initWithFilters: (permanentFilter, filter) => _setFilters(permanentFilter, filter),
        initWithUrlVariableAndFilter: (urlVariables, filter) => _setUrlVarialbesAndFilter(urlVariables, filter),
    };
}

function buildPagingParams(page) {
    return !page ? {} : {
        page: page.number,
        size: page.size
    };
}

function buildSortingParams(sort) {
    return !sort || !sort.orderBy ? {} : {
        sort: [sort.orderBy, sort.order]
    };
}

function buildFilteringParams(filter) {
    if (!filter) {
        return {};
    }
    const result = {};
    Object.entries(filter).forEach(([key, value]) => {
        if (value) {
            result[key] = value;
        }
    });
    return result;
}

function buildUrlWithQuery(url, query) {
    return url + '?' + Object.keys(query)
        .map(key => `${encodeURIComponent(key)}=${buildQueryParamValue(query[key])}`)
        .join('&');
}

function buildQueryParamValue(input) {
    return Array.isArray(input) ?
        Object.values(input).map(value => encodeURIComponent(value)).join(',')
        : encodeURIComponent(input);
}

import {CLOSE_MENU, OPEN_MENU, TOGGLE_MENU} from '../actions/menu';
import type {Action} from './types';

export default function menu(state = {open: false}, action: Action) {
    switch (action.type) {
        case OPEN_MENU:
            return {open: true};
        case CLOSE_MENU:
            return {open: false};
        case TOGGLE_MENU:
            return {open: !state.open};
        default:
            return state;
    }
}
import {createMuiTheme} from '@material-ui/core';
import {blue} from '@material-ui/core/colors';

const colorWhite = '#fff';
const colorDarkBlue = '#18202c';

const tableHeaderBackground = colorDarkBlue;
const tableHeaderColor = colorWhite;

const transparentWhite = 'rgba(255, 255, 255, 0.7)';

export const theme = createMuiTheme({
    palette: {
        primary: blue
    },
    typography: {
        useNextVariants: true,
    },
    custom: {
        grid: {
            spacing: 0
        },
        colors: {
            transparentWhite: transparentWhite
        },
        menu: {
            background: colorDarkBlue,
            color: transparentWhite,
            hoverBackground: 'rgba(255, 255, 255, 0.05)'
        },
        section: {
            marginTop: '2.5em'
        }
    },
    overrides: {
        MuiTableHead: {
            root: {
                backgroundColor: tableHeaderBackground
            }
        },
        MuiTableCell: {
            root: {
                padding: '4px 15px'
            }
        },
        MuiTableSortLabel: {
            root: {
                color: tableHeaderColor,
                opacity: '0.7',
                '&:hover, &:focus': {
                    color: tableHeaderColor,
                    opacity: '1'
                }
            },
            active: {
                color: tableHeaderColor,
                opacity: '1'
            }
        },
        MuiFab: {
            sizeSmall: {
                fontSize: '0.8em'
            }
        }
    }
});
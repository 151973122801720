import {withStyles} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Add, AllInbox, CloudUpload, Home, Search, Settings} from '@material-ui/icons';
import classNames from 'classnames';
import {routerActions} from 'connected-react-router';
import * as PropTypes from 'prop-types';
import React from 'react';
import {withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import * as MenuActions from '../actions/menu';
import LogoErgo from '../components/LogoErgo';
import routes from '../constants/routes';

const menuItems = ['home',
    'campaigns',
    'add-campaigns',
    'search',
    'up-download',
    'admin'];

const icons = {
    'home': <Home/>,
    'campaigns': <AllInbox/>,
    'add-campaigns': <Add/>,
    'search': <Search/>,
    'up-download': <CloudUpload/>,
    'admin': <Settings/>
};

const drawerWidth = '13em';

const styles = theme => {
    return ({
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap'
        },
        drawerContent: {
            background: theme.custom.menu.background,
            '& *': {color: theme.custom.menu.color},
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxShadow: '0 0 20px #000'
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing.unit * 7 + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing.unit * 7 + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
        menuItem: {
            transition: theme.transitions.create('background', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            '&:hover, &:focus': {
                '& *': {color: '#fff'},
                background: theme.custom.menu.hoverBackground,
            },

        },
        menuItemActive: {
            background: theme.custom.menu.hoverBackground,
        },
        link: {
            textDecoration: 'none'
        },
        logoContainer: {
            display: 'block',
            position: 'relative',
            textAlign: 'left',
            margin: '25px 16px 16px 16px',
            padding: '0'
        }
    });
};

function Menu(props) {
    const {classes, t, openMenu, closeMenu, push, open, currentLocation} = props;
    const mouseOver = () => {
        if (!open) openMenu();
    };

    const mouseOut = () => {
        if (open) closeMenu();
    };

    return (
        <Drawer
            variant="permanent"
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: classNames(classes.drawerContent, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
            open={open} onMouseOver={mouseOver} onMouseLeave={mouseOut}>

            <div className={classes.logoContainer}>
                <LogoErgo title="ergo" extended={open}/>
            </div>

            <List>
                {menuItems.map((item) => {
                    const routeUrl = routes[item];
                    const isActive = routeUrl === currentLocation.pathname;
                    return (

                        <ListItem button key={item} onClick={() => {
                            push(routeUrl);
                        }} title={t(item)}
                                  className={classNames(classes.menuItem, {
                                      [classes.menuItemActive]: isActive
                                  })}>
                            <ListItemIcon>{icons[item]}</ListItemIcon>
                            <ListItemText primary={t(item)}/>
                        </ListItem>
                    );
                })}
            </List>
        </Drawer>
    );
}

Menu.propTypes = {
    openMenu: PropTypes.func.isRequired,
    closeMenu: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    open: PropTypes.bool,
    currentLocation: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        open: state.menu.open,
        currentLocation: state.router.location
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({...MenuActions, ...routerActions}, dispatch);
}

export default compose(
    withNamespaces('menu'),
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(Menu);
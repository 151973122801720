import {UPLOAD_COMPLETED, UPLOAD_FAILED, UPLOADING} from '../actions/upload';
import type {Action} from './types';

export default function upload(state = {uploading: false}, action: Action) {
    switch (action.type) {
        case UPLOADING:
            return action.payload;
        case UPLOAD_COMPLETED:
            return action.payload;
        case UPLOAD_FAILED:
            return action.payload;
        default:
            return state;
    }
}

import axios from 'axios';
import {logout} from '../actions/auth';
import {config} from '../config';
import type {Dispatch} from '../reducers/types';
import {authSession} from './authSession';
import {addErrorNotification} from "../actions/notifications";

const noAuthStatuses = [401, 403];

export const getRestClient = (dispatch: Dispatch) => {
    const token = authSession.getAuth() ? authSession.getAuth().token : null;
    const authHeader = token ? {Authorization: `Bearer ${token}`} : null;
    const clientInstance = axios.create({baseURL: config.api.baseUrl, headers: {common: authHeader}});
    clientInstance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (!error.response) {
            dispatch(addErrorNotification('error.service_temporarily_unavailable'))
        } else {
            if (noAuthStatuses.includes(error.response.status)) {
                dispatch(logout());
            }
            if (error.response.status >= 500 && error.response.status < 600 &&
                error.response && error.response.data && !error.response.data.message) {
                dispatch(addErrorNotification('error.service_temporarily_unavailable'))
            }
        }
        return Promise.reject(error);
    });

    return clientInstance;
};

import {config} from '../config';
import type {Dispatch} from '../reducers/types';
import {getRestClient} from '../utils/restClient';
import {addErrorNotification, addSuccessNotification} from './notifications';

export const UPLOADING = 'UPLOADING';
export const UPLOAD_COMPLETED = 'UPLOAD_COMPLETED';
export const UPLOAD_FAILED = 'UPLOAD_FAILED';

function buildFormData(data) {
    const formData = new FormData();
    formData.append('file', data.file);
    return formData;
}

export function upload(data) {
    return (dispatch: Dispatch) => {
        dispatch(uploading());
        const formData = buildFormData(data);
        const requestConfig = {
            headers: {'content-type': 'multipart/form-data'}
        };
        return getRestClient(dispatch).post(config.api.upload, formData, requestConfig)
            .then(() => {
                dispatch(uploadCompleted());
                dispatch(addSuccessNotification('success.file_uploaded_successfully'));
            })
            .catch(error => {
                dispatch(uploadFailed());
                dispatch(addErrorNotification('error.cant_upload_file'));
                if (error.response.status === 400) {
                    handleBadRequest(error.response.data.errors);
                    dispatch(addErrorNotification(error.response.data.message, false))
                } else {
                    dispatch(addErrorNotification(error.response.data.message, false))
                    throw(error);
                }
            });
    };
}

function uploading() {
    return {
        type: UPLOADING,
        payload: {
            uploading: true
        }
    };
}

function uploadCompleted() {
    return {
        type: UPLOAD_COMPLETED,
        payload: {
            uploading: false,
        }
    };
}


function uploadFailed() {
    return {
        type: UPLOAD_FAILED,
        payload: {
            uploading: false,
        }
    };
}

function handleBadRequest(errors) {
    console.warn('The following errors occurred while uploading the file:');
    console.table(errors);
}
const key = 'auth';

export const authSession = {
    save: function (auth) {
        sessionStorage.setItem(key, JSON.stringify(auth));
    },

    clear: function () {
        sessionStorage.removeItem(key);
    },

    getAuth: function () {
        return JSON.parse(sessionStorage.getItem(key));
    }
};

export const active = [
    {
        name: "active.yes",
        value: "true"
    },
    {
        name: "active.no",
        value: "false"
    }
];

export const channel = [
    {
        name: "channel.direct",
        source: [
            {
                name: "channel.direct"
            }
        ]
    },
    {
        name: "channel.e_mail_fremd",
        source: [
            {
                name: "source.pm"
            },
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.e_mail_bestand",
        source: [
            {
                name: "source.emarsys"
            },
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.e_mail_trigger",
        source: [
            {
                name: "source.emarsys"
            },
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.seo",
        source: [
            {
                name: "channel.seo"
            }
        ]
    },
    {
        name: "channel.referral",
        source: [
            {
                name: "channel.referral"
            }
        ]
    },
    {
        name: "channel.social_media",
        source: [
            {
                name: "source.facebook"
            },
            {
                name: "source.instagram"
            },
            {
                name: "source.twitter"
            },
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.sonstige",
        source: [
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.print_to_web",
        source: [
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.tv_to_web",
        source: [
            {
                name: "source.leadlink"
            },
            {
                name: "source.carat"
            },
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.sea_brand",
        source: [
            {
                name: "source.google"
            },
            {
                name: "source.bing"
            },
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.sea_non_brand",
        source: [
            {
                name: "source.google"
            },
            {
                name: "source.bing"
            },
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.sea_retargeting",
        source: [
            {
                name: "source.google"
            },
            {
                name: "source.bing"
            },
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.display",
        source: [
            {
                name: "source.adform"
            },
            {
                name: "source.google"
            },
            {
                name: "source.bing"
            },
            {
                name: "source.pm"
            },
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.display_retargeting",
        source: [
            {
                name: "source.adform"
            },
            {
                name: "source.google"
            },
            {
                name: "source.bing"
            },
            {
                name: "source.pm"
            },
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.paid_social_retargeting",
        source: [
            {
                name: "source.facebook"
            },
            {
                name: "source.instagram"
            },
            {
                name: "source.twitter"
            },
            {
                name: "source.pm"
            },
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.paid_social",
        source: [
            {
                name: "source.facebook"
            },
            {
                name: "source.instagram"
            },
            {
                name: "source.twitter"
            },
            {
                name: "source.pm"
            },
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.video",
        source: [
            {
                name: "source.pm"
            },
            {
                name: "source.google"
            },
            {
                name: "source.adform"
            },
            {
                name: "source.bing"
            },
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.vergleichsportale",
        source: [
            {
                name: "source.check24"
            },
            {
                name: "source.verivox"
            },
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.affiliate",
        source: [
            {
                name: "source.financeads"
            },
            {
                name: "source.awin"
            },
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.kooperationen",
        source: [
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.inbound_calls",
        source: [
            {
                name: "source.intern"
            },
            {
                name: "source.extern"
            },
            {
                name: "source.ksc"
            }
        ]
    },
    {
        name: "channel.outbound_calls",
        source: [
            {
                name: "source.intern"
            },
            {
                name: "source.extern"
            },
            {
                name: "source.ksc"
            }
        ]
    },
    {
        name: "channel.werbemail",
        source: [
            {
                name: "source.andere"
            }
        ]
    },
    {
        name: "channel.bestandspost",
        source: [
            {
                name: "source.andere"
            }
        ]
    }
];

import {withStyles} from '@material-ui/core';
import CircularProgress from '@material-ui/core/es/CircularProgress/CircularProgress';
import React from 'react';
import * as ReactDOM from 'react-dom';
import {withNamespaces} from 'react-i18next';
import {compose} from 'redux';
import {Field, reduxForm, reset} from 'redux-form';
import {CustomButton} from '../../components/CustomizedForms';

const styles = () => {
    return ({
        progress: {
            padding: '0.5em'
        },
        displayGrid: {
            display: 'grid',
            width: 'inherit'
        }
    });
};

function FileInput(props) {
    const {input, accept} = props;
    const {value, ...inputProps} = input;

    const handleChange = (e) => {
        input.onChange(e.target.files[0]);
    };

    return (
        <input {...inputProps} key={new Date()} accept={accept} type="file" style={{display: 'none'}}
               onChange={handleChange} onBlur={() => {
        }}/>
    );
}

const FIELD_NAME = 'file';

function UploadForm(props) {
    const {t, handleSubmit, classes, status, size = 'medium', color = 'primary', label} = props;
    let fileInputReference;

    const openFilePicker = () => {
        ReactDOM.findDOMNode(fileInputReference).click();
    };

    const progress = size !== 'small' ?
        (<React.Fragment>
            <CircularProgress color="primary" className={classes.progress}/>
            {t('uploading')}
        </React.Fragment>)
        : label;

    return (
        <form onSubmit={handleSubmit} className={classes.displayGrid}>
            <Field name={FIELD_NAME} component={FileInput} accept="text/csv"
                   ref={ref => fileInputReference = ref} id="upload-button-file"
                   value={null}/>
            <label htmlFor="upload-button-file" className={classes.displayGrid}>
                <CustomButton component="button" disabled={status.uploading}
                              callback={openFilePicker} size={size} color={color}>
                    {status.uploading ?
                        progress
                        : label}

                </CustomButton>
            </label>
        </form>);
}

function resetForm(result, dispatch) {
    dispatch(reset('upload'));
}

function submitForm(values, props) {
    if (values[FIELD_NAME]) {
        props.submit();
    }
}

export default compose(
    withNamespaces('up_download'),
    withStyles(styles),
    reduxForm({
        form: 'upload',
        onSubmitSuccess: resetForm,
        onSubmitFail: resetForm,
        onChange: (values, dispatch, props, previousValues) => {
            submitForm(values, props);
        }
    })
)(UploadForm);
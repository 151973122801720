// @flow
export const config = {
    api: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        upload: '/api/v1/bulk/campaigns',
        download: '/api/v1/bulk/campaigns',
        getCampaign: '/api/v1/campaigns/',
        allCampaigns: '/api/v1/campaigns',
        allShortlinks: '/api/v1/campaigns/{name}/short-links',
        login: '/api/v1/login',
        initAllDictionaries: '/api/v1/dictionaries',
        getDictionaryValues: '/api/v1/dictionaries/{name}/values',
        saveDictionaryValues: '/api/v1/dictionaries/{name}/values'
    }
};
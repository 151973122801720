import PropTypes from 'prop-types';
import React from 'react';
import {withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import createDataTableActions from '../../actions/dataTableActions';
import createDialogActions from '../../actions/dialog';
import * as UploadActions from '../../actions/upload';
import {CustomButton} from '../../components/CustomizedForms';
import DataTable from '../../components/DataTable';
import PageHeader from '../../components/PageHeader';
import SearchForm from '../../components/SearchForm';
import {config} from '../../config';
import defaultPage from '../../constants/defaults';
import routes from '../../constants/routes';
import UploadForm from '../up-download/UploadForm';

export function getColumnsDefinition(t) {
    return [
        {id: 'name', numeric: false, label: t('columns.name')},
        {id: 'productOwner', numeric: false, label: t('columns.productOwner')},
        {id: 'platform', numeric: false, label: t('columns.platform')},
        {id: 'date', numeric: false, label: t('columns.date')},
        {id: 'actionNumber', numeric: false, label: t('columns.actionNumber')},
        {id: 'partnerWeb', numeric: false, label: t('columns.partnerWeb')},
        {id: 'partnerOeNumber', numeric: false, label: t('columns.partnerOeNumber')},
    ];
}

class CampaignsPage extends React.Component {

    constructor(props) {
        super(props);
        this.setupColumns();
        this.setupSearchTypes();

        this.onRowClicked = this.onRowClicked.bind(this);
        this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.uploadAndRefresh = this.uploadAndRefresh.bind(this);
    }

    setupColumns() {
        const {t} = this.props;
        this.columns = getColumnsDefinition(t);
    }

    setupSearchTypes() {
        const {t} = this.props;
        this.searchTypes = [
            {value: 'name', label: t('search.name')},
            {value: 'actionNumber', label: t('search.actionNumber')},
            {value: 'platform', label: t('search.platform')},
            {value: 'productOwner', label: t('search.productOwner')},
            {value: 'wmId', label: t('search.wmId')}
        ];
    }

    componentDidMount() {
        this.props.init();
    }

    onRowClicked(row) {
        this.props.dialogOpen(row, true);
    }

    onRowDoubleClicked(row) {
        const {history} = this.props;
        history.push(routes.campaigns + '/' + row.cid);
    }

    onSearch(payload) {
        const {setFilter} = this.props;
        setFilter({
            [payload.searchRadio]: payload.searchString
        });
    }

    uploadAndRefresh(data) {
        const {upload, setPage} = this.props;
        upload(data).then(() => setPage(defaultPage));
    }

    render() {
        const {t, campaigns, setPageSize, setPage, setOrder, setOrderBy, uploadStatus, history} = this.props;

        const buttonsLeft = [
            <CustomButton component="button" callback={() => history.push(routes['add-campaigns'])}>
                {t('buttons.add')}
            </CustomButton>];
        const buttonsRight = [
            <UploadForm onSubmit={this.uploadAndRefresh} status={uploadStatus} size={'small'} color="default"
                        label={t('buttons.upload')}/>];

        return (
            <React.Fragment>
                <PageHeader title={t('title')} leftButtons={buttonsLeft} rightButtons={buttonsRight}/>
                <SearchForm onSubmit={this.onSearch}
                            types={this.searchTypes}
                            searchString={campaigns.filter ? Object.values(campaigns.filter).pop() : ''}
                            searchRadio={campaigns.filter ? Object.keys(campaigns.filter).pop() : 'name'}/>
                <DataTable columns={this.columns}
                           data={campaigns.data}
                           order={campaigns.sort.order}
                           setOrder={setOrder}
                           orderBy={campaigns.sort.orderBy}
                           setOrderBy={setOrderBy}
                           page={campaigns.page.number}
                           setPage={setPage}
                           pageSize={campaigns.page.size}
                           setPageSize={setPageSize}
                           totalElements={campaigns.page.totalElements}
                           rowClick={this.onRowClicked}
                           rowDoubleClick={this.onRowDoubleClicked}
                />
            </React.Fragment>
        );
    }
}

CampaignsPage.propTypes = {
    t: PropTypes.func.isRequired,
    campaigns: PropTypes.object.isRequired,
    setPageSize: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    setOrder: PropTypes.func.isRequired,
    setOrderBy: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    upload: PropTypes.func.isRequired,
    uploadStatus: PropTypes.object.isRequired
};


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ...createDataTableActions('CAMPAIGNS', 'campaigns', config.api.allCampaigns),
        ...createDialogActions('CAMPAIGNS', 'campaignsDialog', config.api.allCampaigns),
        ...UploadActions
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        campaigns: state.campaigns,
        uploadStatus: state.upload
    };
}

export default compose(
    withNamespaces('campaigns'),
    connect(mapStateToProps, mapDispatchToProps)
)(CampaignsPage);




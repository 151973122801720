import React from 'react';
import {Redirect, Route} from 'react-router';
import routes from '../../constants/routes';
import LoginPage from './LoginPage';

export default function LoginContainer() {
    return (
        <div className="container">
            <Route exact path={routes.home} render={() => <Redirect to={routes.login}/>}/>
            <Route path={routes.login} component={LoginPage}/>
        </div>
    );
}
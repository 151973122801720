import {config} from '../config/';
import {getRestClient} from '../utils/restClient';
import {generateBaseUrl} from '../utils/urlUtils';
import {addErrorNotification, addSuccessNotification} from './notifications';

export const DICTIONARY_ALL_VALUES_FETCHED = 'DICTIONARY_ALL_VALUES_FETCHED';
export const DICTIONARY_GROUP_SAVED = 'DICTIONARY_GROUP_SAVED';
export const DICTIONARY_VALUES_FETCHED = 'DICTIONARY_VALUES_FETCHED';
export const DICTIONARY_VALUES_SAVED = 'DICTIONARY_VALUES_SAVED';
export const DICTIONARY_VALUE_ADDED = 'DICTIONARY_VALUE_ADDED';
export const DICTIONARY_VALUE_REMOVED = 'DICTIONARY_VALUE_REMOVED';
export const DICTIONARY_VALUES_REORDERED = 'DICTIONARY_VALUES_REORDERED';
export const DICTIONARY_VALUES_UPDATED = 'DICTIONARY_VALUES_UPDATED';

export const DICTIONARY_BRANCH = 'branch';
export const DICTIONARY_ADVERTISE_PRODUCTGROUP = 'productgroup';
export const DICTIONARY_FORMAT = 'format';
export const DICTIONARY_PLATFORM = 'platform';
export const DICTIONARY_PRODUCT_OWNER = 'productowner';
export const DICTIONARY_SALES_AREA = 'salesarea';
export const DICTIONARY_PARTNERS_WEB = 'partnersweb';

export function initAll() {
    return (dispatch) => {
        return getRestClient(dispatch)
            .get(config.api.initAllDictionaries)
            .then(response => dispatch(fetchedAllValues(response.data)))
            .catch(error => {
                throw(error);
            });
    };
}

function fetchedAllValues(dictionaries) {
    let allValues = {};

    if (dictionaries) {
        dictionaries.forEach(dictionary => allValues = {
            ...allValues,
            [dictionary.name]: unwrappedValues(dictionary.values)
        });
    }

    return (dispatch) => {
        dispatch({
            type: DICTIONARY_ALL_VALUES_FETCHED,
            allValues: allValues
        });
    };
}

export function fetchValues(dictionaryName) {
    return (dispatch) => {
        return getRestClient(dispatch)
            .get(generateBaseUrl(config.api.getDictionaryValues, {name: dictionaryName}))
            .then(response => dispatch(fetchedValues(dictionaryName, unwrappedValues(response.data))))
            .catch(error => {
                throw(error);
            });
    };
}

function unwrappedValues(values) {
    const result = [];
    values
        .sort(ascValuesComparator)
        .map(item => result.push(item.value));
    return result;
}

function ascValuesComparator(first, next) {
    return first.order - next.order;
}

function fetchedValues(dictionaryName, values) {
    return (dispatch) => {
        dispatch({
            type: DICTIONARY_VALUES_FETCHED,
            dictionaryName: dictionaryName,
            values: values
        });
    };
}

export function saveValuesInGroup(dictionaries) {
    return (dispatch) => {
        let chainHandler = Promise.resolve(dispatch({type: DICTIONARY_GROUP_SAVED, dictionaries: dictionaries}));

        dictionaries
            .forEach(dictionaryName => chainHandler = chainHandler.then(() => dispatch(saveValues(dictionaryName))));

        chainHandler
            .then(() => dispatch(addSuccessNotification('success.dictionaries_saved_successfully')))
            .catch(() => dispatch(addErrorNotification('error.cant_save_dictionaries')));
    };
}

function saveValues(dictionaryName) {
    return (dispatch, getState) => {
        const values = getState().dictionary.edit[dictionaryName];
        return getRestClient(dispatch)
            .put(generateBaseUrl(config.api.getDictionaryValues, {name: dictionaryName}), wrappedValues(values))
            .then(() => dispatch(savedValues(dictionaryName)))
            .then(() => dispatch(fetchValues(dictionaryName)))
            .catch(error => {
                throw(error);
            });
    };
}


function wrappedValues(values) {
    const result = [];
    values.map((value, index) => result.push({order: index, value: value}));
    return result;
}


function savedValues(dictionaryName) {
    return (dispatch) => {
        dispatch({
            type: DICTIONARY_VALUES_SAVED,
            dictionaryName: dictionaryName
        });
    };
}

export function reorderValues(dictionaryName, change) {
    return (dispatch, getState) => {
        const values = [...getState().dictionary.edit[dictionaryName]];
        if (!change.destination) {
            return;
        }
        const [removed] = values.splice(change.source.index, 1);
        values.splice(change.destination.index, 0, removed);
        dispatch({
            type: DICTIONARY_VALUES_REORDERED,
            dictionaryName: dictionaryName,
            change: change,
            values: values
        });

    };
}

export function updateValue(dictionaryName, index, newValue) {
    return (dispatch, getState) => {
        const values = [...getState().dictionary.edit[dictionaryName]];
        values[index] = newValue;
        dispatch({
            type: DICTIONARY_VALUES_UPDATED,
            dictionaryName: dictionaryName,
            newValue: newValue,
            index: index,
            values: values
        });
    };
}

export function addValue(dictionaryName, newValue) {
    return (dispatch, getState) => {
        const values = [...getState().dictionary.edit[dictionaryName]];
        values.push(newValue);
        dispatch({
            type: DICTIONARY_VALUE_ADDED,
            dictionaryName: dictionaryName,
            newValue: newValue,
            values: values
        });
    };
}

export function removeValue(dictionaryName, index) {
    return (dispatch, getState) => {
        const values = [...getState().dictionary.edit[dictionaryName]];
        values.splice(index, 1);
        dispatch({
            type: DICTIONARY_VALUE_REMOVED,
            dictionaryName: dictionaryName,
            index: index,
            values: values
        });
    };
}
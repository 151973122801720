import {withStyles} from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import PropTypes from 'prop-types';
import React from 'react';
import e from '../assets/e.svg';
import g from '../assets/g.svg';
import o from '../assets/o.svg';
import r from '../assets/r.svg';

const styles = theme => {
    return ({
        hide: {
            display: 'none',
        }
    });
};

function LogoErgo(props) {
    const {extended, title} = props;
    return (
        <div title={title}>
            <img src={e} alt={''}/>
            <FadingImage src={r} visible={extended} timeout={750}/>
            <FadingImage src={g} visible={extended} timeout={1000}/>
            <FadingImage src={o} visible={extended} timeout={1250}/>
        </div>
    );
}

function FadingImage(props) {
    const {src, visible, timeout} = props;
    return (
        <Grow
            in={visible}
            style={{transformOrigin: '0 0 0'}}
            {...(visible ? {timeout: timeout} : {})} >
            <img src={src} alt=""/>
        </Grow>
    );
}

LogoErgo.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    extended: PropTypes.bool
};

export default withStyles(styles)(LogoErgo);


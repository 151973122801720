import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import {Switch} from 'react-router';
import {compose} from 'redux';
import {initAll} from '../actions/dictionary';
import {ProtectedRoute} from '../components/ProtectedRoute';
import routes from '../constants/routes';
import AdminPage from './admin/AdminPage';
import CampaignsDialog from './campaigns/CampaignsDialog';
import CampaignsPage from './campaigns/CampaignsPage';
import Menu from './Menu';
import ShortLinksPage from './short-links/ShortLinksPage';
import UpDownloadPage from './up-download/UpDownloadPage';
import WelcomePage from './welcome/WelcomePage';

const styles = theme => {
    return ({
        mainContainer: {
            [theme.breakpoints.up('xs')]: {
                margin: '1.2em 0.2em 1.2em 4em',
            },
            [theme.breakpoints.up('sm')]: {
                margin: '1.2em 4em 1.2em 8em',
            }
        }
    });
};

function DefaultContainer(props) {
    const {classes, auth} = props;
    return (
        <React.Fragment>
            <Menu/>
            <div className={classes.mainContainer}>
                <Switch>
                    <ProtectedRoute exact auth={auth} path={routes['campaigns']} component={CampaignsPage}/>
                    <ProtectedRoute exact auth={auth} path={routes['add-campaigns']} component={CampaignsPage}/>
                    <ProtectedRoute exact auth={auth} path={routes['campaigns'] + '/:name'} component={ShortLinksPage}/>
                    <ProtectedRoute exact auth={auth} path={routes['up-download']} component={UpDownloadPage}/>
                    <ProtectedRoute exact auth={auth} path={routes['admin']} component={AdminPage}/>
                    <ProtectedRoute auth={auth} path={routes['home']} component={WelcomePage}/>

                </Switch>
                <ProtectedRoute exact auth={auth} path={routes['home']} component={CampaignsDialog}/>
                <ProtectedRoute exact auth={auth} path={routes['campaigns']} component={CampaignsDialog}/>
                <ProtectedRoute exact auth={auth} path={routes['add-campaigns']} component={CampaignsDialog}/>
                <ProtectedRoute exact auth={auth} path={routes['campaigns'] + '/:name'} component={CampaignsDialog}/>
            </div>
        </React.Fragment>
    );
}

DefaultContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    auth: PropTypes.object
};

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

function init(dispatch) {
    dispatch(initAll());
    return {};
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, init)
)(DefaultContainer);



import {
    DICTIONARY_ADVERTISE_PRODUCTGROUP, DICTIONARY_ALL_VALUES_FETCHED,
    DICTIONARY_BRANCH, DICTIONARY_FORMAT, DICTIONARY_PLATFORM, DICTIONARY_PRODUCT_OWNER, DICTIONARY_SALES_AREA, DICTIONARY_PARTNERS_WEB,
    DICTIONARY_VALUES_REORDERED,
    DICTIONARY_VALUE_ADDED,
    DICTIONARY_VALUE_REMOVED,
    DICTIONARY_VALUES_FETCHED,
    DICTIONARY_VALUES_SAVED, DICTIONARY_VALUES_UPDATED
} from '../actions/dictionary';

const initialState = {
    [DICTIONARY_BRANCH]: [],
    [DICTIONARY_ADVERTISE_PRODUCTGROUP]: [],
    [DICTIONARY_FORMAT]: [],
    [DICTIONARY_PLATFORM]: [],
    [DICTIONARY_PRODUCT_OWNER]: [],
    [DICTIONARY_SALES_AREA]: [],
    [DICTIONARY_PARTNERS_WEB]: [],
    edit: {
        [DICTIONARY_BRANCH]: [],
        [DICTIONARY_ADVERTISE_PRODUCTGROUP]: [],
        [DICTIONARY_FORMAT]: [],
        [DICTIONARY_PLATFORM]: [],
        [DICTIONARY_PRODUCT_OWNER]: [],
        [DICTIONARY_SALES_AREA]: [],
        [DICTIONARY_PARTNERS_WEB]: [],
    }
};

export default function dictionary(state = initialState, action) {
    switch (action.type) {
        case DICTIONARY_VALUES_FETCHED:
            return {
                ...state,
                [action.dictionaryName]: action.values,
                edit: {
                    ...state.edit,
                    [action.dictionaryName]: action.values
                }
            };
        case DICTIONARY_VALUES_SAVED:
            return {
                ...state,
                [action.dictionaryName]: [],
                edit: {
                    ...state.edit,
                    [action.dictionaryName]: []
                }
            };
        case DICTIONARY_VALUES_REORDERED:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    [action.dictionaryName]: action.values
                }
            };
        case DICTIONARY_VALUE_ADDED:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    [action.dictionaryName]: action.values
                }
            };
        case DICTIONARY_VALUE_REMOVED:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    [action.dictionaryName]: action.values
                }
            };
        case DICTIONARY_VALUES_UPDATED:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    [action.dictionaryName]: action.values
                }
            };
        case DICTIONARY_ALL_VALUES_FETCHED:
            return {
                ...state,
                ...action.allValues,
                edit: action.allValues
            };
        default:
            return state;
    }

}
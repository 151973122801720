import {withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import {withNamespaces} from 'react-i18next';
import {compose} from 'redux';
import {Field, reduxForm, submit} from 'redux-form';
import {CustomButton, CustomTextField} from '../../components/CustomizedForms';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = {
    mainContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    card: {
        minWidth: 275,
    },
    buttonContainer: {
        textAlign: 'center'
    },
    button: {
        display: 'block'
    },
    error: {
        marginTop: 0,
        textAlign: 'center'
    }
};

function LoginForm(props) {
    const {classes, t, handleSubmit, dispatch, error, submitting} = props;
    return (
        <form onSubmit={handleSubmit}>
            <Grid container justify="center" alignItems="center" className={classes.mainContainer}>
                <Grid item xs={1} sm={2}>
                    <Grid container justify="center" alignItems="center" spacing={16}>

                        <Grid item xs={12}>
                            <Field
                                name="username"
                                component={CustomTextField}
                                label={t('username')}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="password"
                                component={CustomTextField}
                                label={t('password')}
                                fullWidth={true}
                                type="password"
                            />
                        </Grid>
                        {error && !submitting ? <Grid item xs={12}>
                            <FormHelperText error className={classes.error}>{t('invalid_credentials')}</FormHelperText>
                        </Grid> : null}
                        <Grid item xs={12}>
                            <CustomButton type="submit" callback={() => dispatch(submit('login'))}
                                          className={classes.button}>{t('button')}</CustomButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}

LoginForm.propTypes = {
    classes: PropTypes.object.isRequired,
    auth: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired
};

export default compose(
    withNamespaces('login'),
    withStyles(styles),
    reduxForm({form: 'login'}))(LoginForm);

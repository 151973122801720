import {withStyles, withTheme} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import * as DictionaryActions from '../../actions/dictionary';
import {
    DICTIONARY_ADVERTISE_PRODUCTGROUP,
    DICTIONARY_BRANCH,
    DICTIONARY_FORMAT,
    DICTIONARY_PLATFORM,
    DICTIONARY_PRODUCT_OWNER,
    DICTIONARY_SALES_AREA,
    DICTIONARY_PARTNERS_WEB
} from '../../actions/dictionary';
import ConfigurableDropdown from '../../components/ConfigurableDropdown';
import {CustomButton} from '../../components/CustomizedForms';
import PageHeader from '../../components/PageHeader';

const styles = (theme) => ({
    root: {
        marginTop: theme.custom.section.marginTop
    },
    flex: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    label: {
        display: 'inline-block',
        paddingTop: theme.spacing.unit
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '2em'
    }
});


class AdminPage extends React.Component {

    constructor(props) {
        super(props);
        this.setupDictionaries();
    }

    setupDictionaries() {
        const {t} = this.props;
        this.dictinaryGroups =
            [
                {
                    groupLabel: t('shortlink.title'),
                    dictionaries: [
                        {name: DICTIONARY_BRANCH, label: t('shortlink.branch.label')},
                        {name: DICTIONARY_ADVERTISE_PRODUCTGROUP, label: t('shortlink.advertise-productgroup.label')},
                        {name: DICTIONARY_FORMAT, label: t('shortlink.format.label')},
                        {name: DICTIONARY_PARTNERS_WEB, label: t('shortlink.partnersweb.label')}
                    ]
                },
                {
                    groupLabel: t('campaign.title'),
                    dictionaries: [
                        {name: DICTIONARY_PLATFORM, label: t('campaign.platform.label')},
                        {name: DICTIONARY_PRODUCT_OWNER, label: t('campaign.product-owner.label')},
                        {name: DICTIONARY_SALES_AREA, label: t('campaign.sales-area.label')},
                        {name: DICTIONARY_PARTNERS_WEB, label: t('campaign.partnersweb.label')}
                    ]
                },
            ];
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <PageHeader title={t('title')}/>
                {this.dictinaryGroups.map(group => <DictionaryGroup key={group.groupLabel} groupLabel={group.groupLabel}
                                                                    dictionaries={group.dictionaries}
                                                                    {...this.props} />)}
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(DictionaryActions, dispatch);
}


function mapStateToProps(state) {
    return {
        valuesStore: state.dictionary
    };
}

export default compose(
    withNamespaces('admin'),
    withTheme(),
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles))(AdminPage);

const formChanged = (dictionaries, valuesStore) => {
    for (const { name } of dictionaries) {
        if (JSON.stringify(valuesStore[name]) !== JSON.stringify(valuesStore.edit[name])) {
            return true;
        }
    }
    return false;
};

function DictionaryGroup(props) {
    const {dictionaries, theme, groupLabel, classes, saveValuesInGroup, t, valuesStore} = props;

    const saveAllInGroup = () => {
        const dictionaryNames = [];
        dictionaries.forEach(dictionary => dictionaryNames.push(dictionary.name));
        saveValuesInGroup(dictionaryNames);
    };

    return (
        <Grid container spacing={theme.custom.grid.spacing} className={classes.root}>
            <Grid item xs={12} className={classes.sectionSeparator}>
                <Typography component="h2" variant="h5" gutterBottom>{groupLabel}</Typography>
            </Grid>

            {dictionaries.map(dictionary => <DictionaryForm key={dictionary.name} label={dictionary.label}
                                                            dictionaryName={dictionary.name}
                                                            {...props} />)}

            <Grid item xs={12} sm={3} md={2}/>
            <Grid item xs={12} sm={6} md={4} className={classes.buttons}>
                <CustomButton callback={saveAllInGroup} disabled={!formChanged(dictionaries, valuesStore)}>{t('save')}</CustomButton>
            </Grid>
            <Grid item sm={3} md={6}/>

        </Grid>
    );
}

function DictionaryForm(props) {
    const {classes, dictionaryName, label, valuesStore, t, addValue, removeValue, updateValue, reorderValues} = props;
    return (
        <>
            <Grid item xs={12} sm={3} md={2}>
                <InputLabel htmlFor={dictionaryName} className={classes.label}>
                    {label}
                </InputLabel>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.flex}>
                <ConfigurableDropdown items={valuesStore.edit[dictionaryName]}
                                      onReorder={(change) => reorderValues(dictionaryName, change)}
                                      name={dictionaryName}
                                      onChangeValue={(index, value) => updateValue(dictionaryName, index, value)}
                                      onAddValue={(value) => addValue(dictionaryName, value)}
                                      onRemoveValue={(index) => removeValue(dictionaryName, index)}
                                      newEntryText={t('new-entry')}/>
            </Grid>
            <Grid item sm={3} md={6}/>
        </>
    );

}
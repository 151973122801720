import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import {InlineDatePicker} from 'material-ui-pickers';
import React from 'react';
import {withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Field, reduxForm} from 'redux-form';
import dateFormat from '../constants/defaults';
import {CustomButton} from './CustomizedForms';

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 4
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500]
    }
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 4
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        paddingRight: theme.spacing.unit * 3.5,
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2
    }
}))(MuiDialogActions);

const dialogStyles = theme => ({
    paper: {
        width: theme.spacing.unit * 100,
        top: theme.spacing.unit * 8,
        left: theme.spacing.unit * 15,
        position: 'absolute',
        boxShadow: theme.shadows[5]
    }
});

class CustomDialog extends React.Component {
    render() {
        const {
            classes,
            handleSubmit,
            pristine,
            submitting,
            children,
            t,
            dialog,
            onSubmit,
            onClose,
            addTitle,
            editTitle
        } = this.props;

        return (
            <MuiDialog
                hideBackdrop={true}
                onClose={onClose}
                open={dialog.open}
                classes={{ paper: classes.paper }}
            >
                <DialogTitle onClose={onClose}>
                    {dialog.edit ? editTitle : addTitle}
                </DialogTitle>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <Grid container spacing={8}>
                            {children}
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <CustomButton
                            component="button"
                            type="submit"
                            disabled={pristine || submitting}
                        >
                            {dialog.edit ? t('update') : t('add')}
                        </CustomButton>

                        <CustomButton
                            component="button"
                            color="default"
                            callback={onClose}
                            disabled={pristine || submitting}
                        >
                            {t('cancel')}
                        </CustomButton>
                    </DialogActions>
                </form>
            </MuiDialog>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        form: props.dialog.form
    };
}

export const Dialog = compose(
    withStyles(dialogStyles),
    connect(
        mapStateToProps,
        null
    ),
    withNamespaces('common'),
    reduxForm({
        enableReinitialize: true,
        keepDirtyOnReinitialize: true
    })
)(CustomDialog);

function checkIfHasError(meta, error) {
    return (meta.touched && meta.error) || error !== undefined;
}


function getErrorMessage(value, hasError, error, t) {
    if (!hasError) {
        return null;
    }

    if (error) {
        return t('error.' + error);
    }

    if (value.includes('"')) {
        return t('error.forbidden_quote');
    }

    return t('error.required_field');
}

const renderTextField = ({
    input: { name, value, onChange }, multiline, disabled, meta, validationError, t
}) => {
    const hasError = checkIfHasError(meta, validationError);
    const errorMessage = getErrorMessage(value, hasError, validationError, t);
    return (
        <TextField
            id={name}
            value={value}
            onChange={onChange}
            fullWidth
            helperText={errorMessage}
            error={hasError}
            multiline={multiline}
            disabled={disabled}
        />
    );
};

const renderDateField = ({
    input: { name, value, onChange }, meta, validationError, t
}) => {
    const hasError = checkIfHasError(meta, validationError);
    const errorMessage = getErrorMessage(hasError, validationError, t);
    return (
        <InlineDatePicker
            id={name}
            openTo='month'
            views={['year', 'month']}
            format={dateFormat.dateFormat}
            value={value ? parse(value, dateFormat.dateFormat, new Date()) : null}
            onChange={value => onChange(format(value, dateFormat.dateFormat))}
            clearable
            fullWidth
            helperText={errorMessage}
            error={hasError}
        />
    );
};

const renderSelectField = ({
    input: { name, value, onChange }, meta, validationError, t,
    values
}) => {
    const hasError = checkIfHasError(meta, validationError);
    const errorMessage = getErrorMessage(hasError, validationError, t);
    return (
        <FormControl fullWidth>
            <Select
                id={name}
                value={value}
                onChange={onChange}
                fullWidth
                error={hasError}
                disabled={!values.length}
            >
                {values.map((value, key) => (
                    <MenuItem value={value.value || value.name || value} key={key}>
                        {value.name || value}
                    </MenuItem>
                ))}
            </Select>
            {hasError ? <FormHelperText error={hasError}>{errorMessage}</FormHelperText> : null}
        </FormControl>
    );
};


const validateRequired = value => (value ? undefined : true);
const validateQuote = value => (value && value.includes('"') ? true : undefined);

const parseNumber = value => value.toString().replace(/\D+/g, '');

const parseNumberWithMaxLength = length => value => parseNumber(value).slice(0, length);

export const DialogField = compose(
    withNamespaces('notifications'),
    withStyles(theme => ({
        label: {
            display: 'inline-block',
            paddingTop: theme.spacing.unit
        }
    }))
)(({ field, name, label, required, classes, number, errors, ...custom }) => {
    const error = errors ? errors.get(name) : null;
    return (
        <React.Fragment>
            <Grid item xs={5}>
                <InputLabel htmlFor={name} className={classes.label}>
                    {label}
                </InputLabel>
            </Grid>
            <Grid item xs={7}>
                {field === 'text' ? (
                    <Field
                        component={renderTextField}
                        name={name}
                        validationError={error}
                        validate={required ? [validateRequired, validateQuote] : validateQuote}
                        parse={number ? parseInt(number) ? parseNumberWithMaxLength(number) : parseNumber : null}
                        {...custom}
                    />
                ) : null}
                {field === 'date' ? (
                    <Field
                        component={renderDateField}
                        name={name}
                        validationError={error}
                        validate={required ? [validateRequired] : null}
                        {...custom}
                    />
                ) : null}
                {field === 'select' ? (
                    <Field
                        component={renderSelectField}
                        name={name}
                        validationError={error}
                        validate={required ? [validateRequired] : null}
                        {...custom}
                    />
                ) : null}
            </Grid>
        </React.Fragment>
    );
});

import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { config } from '../../config';
import * as campaignActions from '../../actions/campaign';
import createDataTableActions from '../../actions/dataTableActions';
import createDialogActions from '../../actions/dialog';
import { Dialog, DialogField } from '../../components/Dialog';
import routes from '../../constants/routes';

class CampaignsDialog extends React.Component {
    constructor(props) {
        super(props);

        this.onDialogSubmit = this.onDialogSubmit.bind(this);
        this.onDialogClose = this.onDialogClose.bind(this);
    }

    componentDidMount() {
        if (this.isAddLocation()) {
            this.props.dialogOpen();
        }
    }

    onDialogSubmit(payload) {
        const {dialogSubmit, init, match, getCampaign} = this.props;

        dialogSubmit(payload).then(() => {
            init();
            if (match.params.name) {
                getCampaign(match.params.name);
            }
            this.goBack();
        });
    }

    onDialogClose() {
        this.props.dialogClose();
        this.goBack();
    }

    goBack() {
        if (this.isAddLocation()) {
            this.props.history.push(routes['campaigns']);
        }
    }

    isAddLocation() {
        return this.props.location.pathname === routes['add-campaigns'];
    }

    render() {
        const { t, dialog, dictionary: { platform, productowner, salesarea, partnersweb } } = this.props;

        return (
            <Dialog
                dialog={dialog}
                onSubmit={this.onDialogSubmit}
                onClose={this.onDialogClose}
                addTitle={t('dialog.add')}
                editTitle={t('dialog.edit')}
            >
                <DialogField errors={dialog.errors} field="select" name="platform" label={t('columns.platform')} values={platform} required />
                <DialogField errors={dialog.errors} field="select" name="productOwner" label={t('columns.productOwner')} values={productowner} required />
                <DialogField errors={dialog.errors} field="text" name="name" label={t('columns.name')} required disabled multiline />
                <DialogField errors={dialog.errors} field="select" name="salesArea" label={t('columns.salesArea')} values={salesarea} required />
                <DialogField errors={dialog.errors} field="text" name="actionNumber" label={t('columns.actionNumber')} required number={5} />
                <DialogField errors={dialog.errors} field="text" name="serviceNumber" label={t('columns.serviceNumber')} />
                <DialogField errors={dialog.errors} field="text" name="brokerNumber" label={t('columns.brokerNumber')} />
                <DialogField errors={dialog.errors} field="select" name="partnerWeb" label={t('columns.partnerWeb')} values={partnersweb} />
                <DialogField errors={dialog.errors} field="text" name="partnerOeNumber" label={t('columns.partnerOeNumber')} />
                <DialogField errors={dialog.errors} field="date" name="date" label={t('columns.date')} required />
            </Dialog>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ...campaignActions,
        ...createDataTableActions('CAMPAIGNS', 'campaigns', config.api.allCampaigns),
        ...createDialogActions('CAMPAIGNS', 'campaignsDialog', config.api.allCampaigns)
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        dictionary: state.dictionary,
        dialog: state.campaignsDialog
    };
}

export default compose(
    withNamespaces('campaigns'),
    connect(mapStateToProps, mapDispatchToProps)
)(CampaignsDialog);

import Fab from '@material-ui/core/Fab';
import RadioButtonGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import defaults from '../constants/defaults';

export function CustomButton(props) {
    const {
        children,
        callback,
        variant = 'extended',
        color = 'primary',
        component = 'button',
        size = 'medium',
        className,
        disabled,
        type,
    } = props;
    return (
        <Fab
            variant={variant}
            color={color}
            onClick={callback}
            size={size}
            component={component}
            className={className}
            disabled={disabled}
            type={type}>
            {children}
        </Fab>
    );
}

export function CustomTextField(props) {
    const {
        label,
        input,
        meta: {touched, invalid, error},
        ...custom
    } = props;
    return (
        <TextField
            label={label}
            placeholder={label}
            error={touched && invalid}
            helperText={touched && error}
            variant={defaults.defaultInputVariant}
            margin="none"
            {...input}
            {...custom}
        />
    );
}

export function CustomRadioGroup({input, ...rest}) {
    return (
        <RadioButtonGroup
            row
            {...input}
            {...rest}
            onChange={(event, value) => input.onChange(value)}
        />
    );
}


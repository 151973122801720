import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { config } from '../../config';
import createDataTableActions from '../../actions/dataTableActions';
import createDialogActions from '../../actions/dialog';
import { Dialog, DialogField } from '../../components/Dialog';
import { active, channel } from './dropdowns';

class ShortLinksDialog extends React.Component {
    constructor(props) {
        super(props);

        this.active = this.translate(active);
        this.channel = this.translate(channel);

        this.onDialogSubmit = this.onDialogSubmit.bind(this);
    }

    translate(values) {
        const { t } = this.props;

        return values.map(value => {
            value.name = t(`dictionaries:${value.name}`);
            if (value.source) {
                value.source.map(val => {
                    val.name = t(`dictionaries:${val.name}`);
                    return val;
                });
            }
            return value;
        });
    }

    onDialogSubmit({$campaignName, ...payload}) {
        const { dialogSubmit, init } = this.props;

        dialogSubmit(payload).then(() => {
            init();
        });
    }

    render() {
        const { t, dialog, dialogClose, dictionary: { format, productgroup, branch, partnersweb } } = this.props;
        return (
            <Dialog
                dialog={dialog}
                onSubmit={this.onDialogSubmit}
                onClose={dialogClose}
                addTitle={t('dialog.add')}
                editTitle={t('dialog.edit')}
            >
                <DialogField errors={dialog.errors} field="text" name="wmId" label={t('columns.wmId')} disabled />
                <DialogField errors={dialog.errors} field="select" name="active" label={t('columns.active')} values={this.active} required />
                <DialogField errors={dialog.errors} field="text" name="targetGroup" label={t('columns.targetGroup')} />
                <DialogField errors={dialog.errors} field="select" name="format" label={t('columns.format')} values={format} required />
                <DialogField errors={dialog.errors} field="text" name="publisherPlatform" label={t('columns.publisherPlatform')} required />
                <DialogField errors={dialog.errors} field="select" name="channel" label={t('columns.channel')} values={this.channel} required />
                <DialogField errors={dialog.errors} field="select" name="source" label={t('columns.source')} values={dialog.source} required />
                <DialogField errors={dialog.errors} field="text" name="shortLinkName" label={t('columns.shortLinkName')} required disabled multiline />
                <DialogField errors={dialog.errors} field="text" name="partner" label={t('columns.partner')} />
                <DialogField errors={dialog.errors} field="text" name="targetUrl" label={t('columns.targetUrl')} required />
                <DialogField errors={dialog.errors} field="text" name="targetUrlParameter" label={t('columns.targetUrlParameter')} />
                <DialogField errors={dialog.errors} field="text" name="friendlyUrl" label={t('columns.friendlyUrl')} />
                <DialogField errors={dialog.errors} field="text" name="b2bBrokerNumber" label={t('columns.b2bBrokerNumber')} />
                <DialogField errors={dialog.errors} field="select" name="advertisedProductGroup" label={t('columns.advertisedProductGroup')} values={productgroup} />
                <DialogField errors={dialog.errors} field="select" name="branch" label={t('columns.branch')} values={branch} required />
                <DialogField errors={dialog.errors} field="text" name="internalCustomer" label={t('columns.internalCustomer')} required />
                <DialogField errors={dialog.errors} field="text" name="actionNumber" label={t('columns.actionNumber')} required number={5} />
                <DialogField errors={dialog.errors} field="text" name="serviceNumber" label={t('columns.serviceNumber')} />
                <DialogField errors={dialog.errors} field="select" name="partnerWeb" label={t('columns.partnerWeb')} values={partnersweb} />
                <DialogField errors={dialog.errors} field="text" name="partnerOeNumber" label={t('columns.partnerOeNumber')} />
            </Dialog>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ...createDataTableActions('SHORT_LINKS', 'shortLinks', config.api.allShortlinks),
        ...createDialogActions('SHORT_LINKS', 'shortLinksDialog', config.api.allShortlinks)
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        dictionary: state.dictionary,
        dialog: state.shortLinksDialog
    };
}

export default compose(
    withNamespaces('short_links'),
    connect(mapStateToProps, mapDispatchToProps)
)(ShortLinksDialog);

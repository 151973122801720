import {withStyles} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';
import React from 'react';
import {withNamespaces} from 'react-i18next';
import {compose} from 'redux';
import {Field, reduxForm, submit} from 'redux-form';
import {CustomButton, CustomRadioGroup, CustomTextField} from './CustomizedForms';

const style = () => ({
    container: {
        marginTop: '1em'
    },
});

class SearchForm extends React.Component {

    componentDidMount() {
        const {searchString, searchRadio, change} = this.props;
        change('searchString', searchString);
        change('searchRadio', searchRadio);
    }

    render() {
        const {handleSubmit, dispatch, t, classes, types} = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <Grid container justify="flex-start" alignItems="center" spacing={16} className={classes.container}>
                    <Grid item xs={9} md={6}>
                        <Field
                            name="searchString"
                            component={CustomTextField}
                            fullWidth={true}
                            className={classes.field}
                        />
                    </Grid>
                    <Grid item xs={3} md={6}>
                        <CustomButton callback={() => dispatch(submit('search'))}>
                            {t('search')}</CustomButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Field name="searchRadio" component={CustomRadioGroup}>
                            {types.map(paramType => (
                                <FormControlLabel
                                    key={paramType.value}
                                    value={paramType.value}
                                    control={<Radio color="primary"/>}
                                    label={paramType.label}
                                    labelPlacement="end"
                                />
                            ))}
                        </Field>
                    </Grid>
                </Grid>
            </form>
        );
    }
}


SearchForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    types: PropTypes.array.isRequired,
    searchString: PropTypes.string,
    searchRadio: PropTypes.string,
};

export default compose(
    withNamespaces('common'),
    withStyles(style),
    reduxForm({form: 'search', enableReinitialize: true})
)(SearchForm);
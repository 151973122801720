// @flow
import i18n from 'i18next';
import {reactI18nextModule} from 'react-i18next';
// Language Files
import en from './en';

const defaultLanguage = 'en';
const isDev = process.env.NODE_ENV !== 'production';

i18n.use(reactI18nextModule)
    .init({
        lng: defaultLanguage,
        fallbackLng: defaultLanguage,
        debug: isDev,
        resources: {
            en
        },
        interpolation: {
            escapeValue: false
        },
    });

export default i18n;

import PropTypes from 'prop-types';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import * as AuthActions from '../../actions/auth';
import LoginForm from './LoginForm';


function LoginPage(props) {
    const {login} = props;
    return (
        <LoginForm onSubmit={login}/>
    );
}

LoginPage.propTypes = {
    login: PropTypes.func
};

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(AuthActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
import { initialize } from 'redux-form';
import {getRestClient} from '../utils/restClient';
import {generateBaseUrl} from '../utils/urlUtils';

export default function createDialogActions(dialogName, reducer, apiUrl = '') {
    function _dialogOpen(payload = null, edit = false) {
        return dispatch => {
            dispatch(initialize(`${dialogName}`, payload, true));
            dispatch({
                type: `DIALOG_${dialogName}_OPEN`,
                edit,
                payload
            });
        };
    }

    function _dialogClose() {
        return dispatch => {
            dispatch({
                type: `DIALOG_${dialogName}_CLOSE`
            });
        };
    }

    function _handleBadRequest(errors) {
        return dispatch => {
            dispatch({
                type: `DIALOG_${dialogName}_BAD_REQUEST`,
                errors: errors ? new Map(errors.map(e => [e.field, e.defaultMessage])) : new Map()
            });
        };
    }

    function _dialogSubmit(payload) {
        return (dispatch, getState) => {
            const state = getState()[reducer];
            return getRestClient(dispatch)
                .post(generateBaseUrl(apiUrl, state.urlVariables), payload)
                .then(response => {
                    dispatch({
                        type: `DIALOG_${dialogName}_SUBMITTED`
                    });
                })
                .catch(error => {
                    if (error.response.status === 400) {
                        dispatch(_handleBadRequest(error.response.data.errors));
                    } else {
                        throw error;
                    }
                });
        };
    }

    function _initDialoghUrlVariable(urlVariables) {
        return (dispatch) => {
            dispatch({
                type: `DIALOG_${dialogName}_URL`,
                urlVariables
            });
        };
    }

    return {
        dialogOpen: _dialogOpen,
        dialogClose: _dialogClose,
        dialogSubmit: _dialogSubmit,
        initDialoghUrlVariable: _initDialoghUrlVariable
    };
}

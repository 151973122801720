import {AUTHENTICATE, INVALID_CREDENTIALS, LOGOUT} from '../actions/auth';
import {authSession} from '../utils/authSession';
import type {Action} from './types';

export default function auth(state, action: Action) {
    switch (action.type) {
        case AUTHENTICATE:
            authSession.save(action.payload);
            return action.payload;
        case INVALID_CREDENTIALS:
            authSession.clear();
            return action.payload;
        case LOGOUT:
            authSession.clear();
            return null;
        default:
            return authSession.getAuth();
    }
}
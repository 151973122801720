import {InputBase, MenuItem, Select, withStyles} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {AddCircleOutline, DragHandle, RemoveCircleOutline} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Portal from './Portal';

const styles = (theme) => (
    {

        item: {
            flex: 1,
            justifyContent: 'space-between',
            outline: 'none',
            backgroundColor: theme.custom.colors.transparentWhite
        },
        dragHandler: {
            opacity: '0.3',
            '&:hover, &:active': {
                opacity: '1'
            }
        },
        textField: {
            width: '100%',
            paddingRight: '4em',
            boxSizing: 'border-box'
        },
        fullWidth: {
            width: '100%'
        }
    }
);

function ConfigurableDropdown(props) {
    const {
        items,
        classes,
        name,
        newEntryText,
        onReorder,
        onChangeValue,
        onAddValue,
        onRemoveValue
    } = props;

    const onChangeValueHandler = (index, event) => {
        onChangeValue(index, event.target.value);
    };

    const onAddValueHandler = () => {
        onAddValue(newEntryText);
    };

    const onRemoveValueHandler = (index) => {
        onRemoveValue(index);
    };

    return (

        <Select
            name={name}
            fullWidth
            value={''}
            MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                }
            }}>
            <DragDropContext onDragEnd={onReorder}>
                <Droppable droppableId={name}>
                    {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {
                                items.map((item, index) => (
                                    <Draggable draggableId={index.toString()} index={index} key={index}>
                                        {(draggableProvided, draggableSnapshot,) => (
                                            <DropdownValue value={item}
                                                           onChangeValue={(e) => onChangeValueHandler(index, e)}
                                                           onRemoveValue={() => onRemoveValueHandler(index)}
                                                           classes={classes}
                                                           provided={draggableProvided}
                                                           snapshot={draggableSnapshot}/>
                                        )}
                                    </Draggable>
                                ))
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                <MenuItem className={classes.item} button={false}>
                    <div >
                        <IconButton onClick={onAddValueHandler}>
                            <AddCircleOutline fontSize={'small'}/>
                        </IconButton>
                        {newEntryText}
                    </div>
                </MenuItem>
            </DragDropContext>
        </Select>

    );
}

ConfigurableDropdown.propTypes = {
    items: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    onReorder: PropTypes.func.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    onAddValue: PropTypes.func.isRequired,
    onRemoveValue: PropTypes.func.isRequired,
    newEntryText: PropTypes.string,
};

export default withStyles(styles)(ConfigurableDropdown);


function DropdownValue(props) {
    const {value, classes, provided, snapshot, onChangeValue, onRemoveValue} = props;
    const usePortal = snapshot.isDragging;
    const handleFocus = (event) => event.target.select()
    const node = (
        <div {...provided.draggableProps}
             ref={provided.innerRef}>
            <MenuItem className={classes.item} button={false}>
                <div className={classes.fullWidth} >
                    <IconButton onClick={onRemoveValue}>
                        <RemoveCircleOutline fontSize={'small'}/>
                    </IconButton>
                    <InputBase value={value} onChange={onChangeValue} onFocus={handleFocus} className={classes.textField}/>
                </div>
                <div  {...provided.dragHandleProps}>
                    <DragHandle className={classes.dragHandler}/>
                </div>
            </MenuItem>
        </div>
    );
    return usePortal ? (<Portal>{node}</Portal>) : node;
}

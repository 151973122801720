import {withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import {withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import * as campaignActions from '../../actions/campaign';
import createDialogActions from '../../actions/dialog';
import {CustomButton} from '../../components/CustomizedForms';
import {config} from '../../config';

const style = () => ({
    title: {
        margin: '0.5em 0 0 0'
    },
    button: {
        margin: '0.6em 0 0 0'
    }
});

class CampaignHeader extends React.Component {

    componentDidMount() {
        const {getCampaign, name} = this.props;
        getCampaign(name);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const {dialogOpen, campaign} = this.props;
        dialogOpen(campaign, true);
    }

    render() {
        const {t, campaign, classes} = this.props;

        return !campaign ? '' : (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h6" className={classes.title} gutterBottom>
                        {campaign.productOwner}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom>
                        {t('campaignHeader.name')} {campaign.name}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom>
                        {t('campaignHeader.actionNumber')} {campaign.actionNumber}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom>
                        {t('campaignHeader.serviceNumber')} {campaign.serviceNumber}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <CustomButton component="button" size="small" color="default" className={classes.button} callback={this.handleClick}>
                        {t('campaignHeader.editHeaderButton')}
                    </CustomButton>
                </Grid>
            </Grid>

        );
    }
}

CampaignHeader.propTypes = {
    t: PropTypes.func.isRequired,
    getCampaign: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    campaign: PropTypes.object,
};


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ...campaignActions,
        ...createDialogActions('CAMPAIGNS', 'campaignsDialog', config.api.allCampaigns)
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        campaign: state.campaign
    };
}

export default compose(
    withNamespaces('short_links'),
    withStyles(style),
    connect(mapStateToProps, mapDispatchToProps)
)(CampaignHeader);
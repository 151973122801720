import React from 'react';
import {render} from 'react-dom';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import {configureStore, history} from './store/configureStore';

const store = configureStore();

render(<App store={store} history={history}/>, document.getElementById('root'));

serviceWorker.unregister();

import {withStyles} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
    container: {
        width: '100%'
    },
    contentContainer: {
        margin: '1em 0',

    },
    header: {
        display: 'table-row'
    },
    title: {
        fontWeight: 'bold',
        display: 'table-cell',
        paddingRight: '0.5em',
        whiteSpace: 'nowrap'
    },
    buttons: {
        display: 'table-cell',
        width: '100%',
        verticalAlign: 'bottom'


    },
    rightContainer: {
        float: 'right'
    },
    buttonWrapper: {
        display: 'inline-block',
        marginLeft: '1em'
    }
};

function PageHeader(props) {
    const {classes, title, rightButtons, leftButtons} = props;
    return (
        <div className={classes.container}>
            <div className={classes.contentContainer}>
                <div className={classes.header}>
                    <Typography className={classes.title} component="h1" variant="h4" gutterBottom>
                        {title}
                    </Typography>
                    <div className={classes.buttons}>
                        <span >
                            {!leftButtons ? ''
                                : leftButtons.map((button, index) => <span key={index}
                                                                            className={classes.buttonWrapper}>{button}</span>)}
                        </span>
                        <span className={classes.rightContainer}>
                            {!rightButtons ? ''
                                : rightButtons.map((button, index) => <span key={index}
                                                                            className={classes.buttonWrapper}>{button}</span>)}
                        </span>
                    </div>
                </div>
            </div>
            <Divider/>
        </div>
    );
}

PageHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    rightButtons: PropTypes.array,
    leftButtons: PropTypes.array
};

export default withStyles(styles)(PageHeader);
import {routerActions} from 'connected-react-router';
import type {Dispatch} from '../reducers/types';
import {getRestClient} from '../utils/restClient';
import {config} from "../config";
import {SubmissionError} from 'redux-form';
import routes from "../constants/routes"
export const AUTHENTICATE = 'AUTHENTICATE';
export const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS';
export const LOGOUT = 'LOGOUT';


export function login(credentials) {
    return async (dispatch: Dispatch) => {
        try {
            const authResponse = await performAuthentication(credentials, dispatch);
            if (authResponse && authResponse.token) {
                dispatch(authenticate(authResponse));
                dispatch(routerActions.push(routes.home));
                return Promise.resolve();
            } else {
                dispatch(invalidCredentials(authResponse));
            }
        } catch (e) {
            dispatch(invalidCredentials((e.response && e.response.data) || null));
        }
        throw new SubmissionError({
            _error: true
        });
    };
}

export function logout() {
    return {
        type: LOGOUT
    };
}

function authenticate(auth) {
    return {
        type: AUTHENTICATE,
        payload: auth
    };
}

function invalidCredentials(auth) {
    return {
        type: INVALID_CREDENTIALS,
        payload: auth
    };
}

function performAuthentication(credentials, dispatch) {
    return getRestClient(dispatch).post(config.api.login, credentials).then(response => response.data);
}

import {withStyles} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import createDataTableActions from '../../actions/dataTableActions';
import * as UploadActions from '../../actions/upload';
import DataTable from '../../components/DataTable';
import PageHeader from '../../components/PageHeader';
import SearchForm from '../../components/SearchForm';
import {config} from '../../config';
import defaultPage from '../../constants/defaults';
import DownloadForm from '../up-download/DownloadForm';
import UploadForm from '../up-download/UploadForm';
import CampaignHeader from './CampaignHeader';
import { CustomButton } from '../../components/CustomizedForms';
import createDialogActions from '../../actions/dialog';
import ShortLinksDialog from './ShortLinksDialog';

const styles = () => (
    {
        bullet: {
            width: '0.6em',
            height: '0.6em',
            borderRadius: '50%',
            display: 'inline-block',
            background: 'red',
            marginRight: '0.5em',
        },
        bulletActive: {
            background: 'green'
        }

    }
);
const defaultSearchRadio = 'true';

class ShortLinksPage extends React.Component {

    constructor(props) {
        super(props);
        this.setupColumns();
        this.setupSearchTypes();

        this.onRowClicked = this.onRowClicked.bind(this);
        this.onDialogOpen = this.onDialogOpen.bind(this);
        this.formatter = this.formatter.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.uploadAndRefresh = this.uploadAndRefresh.bind(this);
    }

    setupColumns() {
        const {t} = this.props;
        this.columns = [
            {id: 'wmId', numeric: false, label: t('columns.wmId')},
            {id: 'friendlyUrl', numeric: false, label: t('columns.friendlyUrl')},
            {id: 'channel', numeric: false, label: t('columns.channel')},
            {id: 'source', numeric: false, label: t('columns.source')},
            {id: 'actionNumber', numeric: false, label: t('columns.actionNumber')},
            {id: 'serviceNumber', numeric: false, label: t('columns.serviceNumber')},
            {id: 'b2bBrokerNumber', numeric: false, label: t('columns.b2bBrokerNumber')},
            {id: 'active', numeric: false, label: t('columns.active')},
            {id: 'targetUrl', numeric: false, label: t('columns.targetUrl')}
        ];
    }

    setupSearchTypes() {
        const {t} = this.props;
        this.searchTypes = [
            {value: 'true', label: t('search.active')},
            {value: 'false', label: t('search.inactive')}
        ];
    }

    componentDidMount() {
        this.props.initWithUrlVariableAndFilter({name: this.props.match.params.name}, {active: defaultSearchRadio});
        this.props.initDialoghUrlVariable({name: this.props.match.params.name});
    }

    onSearch(payload) {
        const {setFilter} = this.props;
        setFilter({
            wmId: payload.searchString,
            active: payload.searchRadio
        });
    }

    uploadAndRefresh(data) {
        const {upload, setPage} = this.props;
        upload(data).then(() => setPage(defaultPage));
    }

    formatter(row) {
        const {t, classes} = this.props;
        const isActive = row.active === 'true';
        return (
            {
                ...row,
                active: (
                    <span>
                        <span className={classNames(classes.bullet, {[classes.bulletActive]: isActive})}/>
                        {t(isActive ? 'common:yes' : 'common:no')}
                    </span>
                )
            }
        );
    }

    onRowClicked(row) {
        const { dialogOpen, campaign } = this.props;

        dialogOpen({$campaignName: campaign.name, ...row}, true);
    }

    onDialogOpen() {
        const { dialogOpen, campaign } = this.props;

        dialogOpen({
            b2bBrokerNumber: campaign.brokerNumber,
            actionNumber: campaign.actionNumber,
            serviceNumber: campaign.serviceNumber,
            $campaignName: campaign.name,
            campaignId: campaign.cid,
            partnerWeb: campaign.partnerWeb,
            partnerOeNumber: campaign.partnerOeNumber
        });
    }

    render() {
        const {t, shortLinks, setPageSize, setPage, setOrder, setOrderBy, uploadStatus} = this.props;
        const campaignId = this.props.match.params.name;

        const buttonsLeft = [
            <CustomButton component="button" callback={this.onDialogOpen}>
                {t('buttons.add')}
            </CustomButton>];
        const buttonsRight = [
            <UploadForm onSubmit={this.uploadAndRefresh} status={uploadStatus} size={'small'} color="default"
                        label={t('buttons.upload')}/>,
            <DownloadForm campaignId={campaignId} size={'small'} color="default" label={t('buttons.download')}/>];

        return (
            <React.Fragment>
                <PageHeader title={t('title')} leftButtons={buttonsLeft} rightButtons={buttonsRight}/>
                <SearchForm onSubmit={this.onSearch}
                            types={this.searchTypes}
                            searchString={shortLinks.filter ? shortLinks.filter.wmid : ''}
                            searchRadio={String(shortLinks.filter ? shortLinks.filter.active : defaultSearchRadio)}
                />
                <CampaignHeader name={this.props.match.params.name}/>
                <DataTable columns={this.columns}
                           data={shortLinks.data}
                           order={shortLinks.sort.order}
                           setOrder={setOrder}
                           orderBy={shortLinks.sort.orderBy}
                           setOrderBy={setOrderBy}
                           page={shortLinks.page.number}
                           setPage={setPage}
                           pageSize={shortLinks.page.size}
                           setPageSize={setPageSize}
                           totalElements={shortLinks.page.totalElements}
                           customFormatter={this.formatter}
                           rowClick={this.onRowClicked}
                />
                <ShortLinksDialog />
            </React.Fragment>
        );
    }
}

ShortLinksPage.propTypes = {
    t: PropTypes.func.isRequired,
    shortLinks: PropTypes.object.isRequired,
    setPageSize: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    setOrder: PropTypes.func.isRequired,
    setOrderBy: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    upload: PropTypes.func.isRequired,
    uploadStatus: PropTypes.object.isRequired
};


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ...createDataTableActions('SHORT_LINKS', 'shortLinks', config.api.allShortlinks),
        ...createDialogActions('SHORT_LINKS', 'shortLinksDialog', config.api.allShortlinks),
        ...UploadActions
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        campaign: state.campaign,
        shortLinks: state.shortLinks,
        uploadStatus: state.upload
    };
}

export default compose(
    withStyles(styles),
    withNamespaces(['short_links', 'common']),
    connect(mapStateToProps, mapDispatchToProps)
)(ShortLinksPage);




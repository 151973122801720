import {withSnackbar} from 'notistack';
import {Component} from 'react';
import {withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {removeNotification} from '../actions/notifications';

class Notifier extends Component {
    displayed = [];

    storeDisplayed = id => {
        this.displayed = [...this.displayed, id];
    };

    shouldComponentUpdate({notifications: newSnacks = []}) {
        const {notifications: currentSnacks} = this.props;
        let notExists = false;
        for (let i = 0; i < newSnacks.length; i++) {
            if (notExists) continue;
            notExists = notExists || !currentSnacks.filter(({key}) => newSnacks[i].key === key).length;
        }
        return notExists;
    }

    componentDidUpdate() {
        const {notifications = [], t} = this.props;
        notifications.forEach(notification => {
            if (this.displayed.includes(notification.key) || !notification.message) return;

            const message = notification.isMessageDict ? t(notification.message) : notification.message;
            this.createSnackbar(notification.options, message);

            this.storeDisplayed(notification.key);
            this.props.removeNotification(notification.key);
        });
    }

    createSnackbar(notificationOptions, notificationMessage) {
        const snackbarKey = this.props.enqueueSnackbar(
            notificationMessage,
            {
                ...notificationOptions,
                onClick: () => {
                    this.props.closeSnackbar(snackbarKey)
                }
            }
        );
    }

    render() {
        return null;
    }
}

function mapStateToProps(state) {
    return {
        notifications: state.notifications.notifications,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({removeNotification}, dispatch);
}

export default compose(
    withNamespaces('notifications'),
    connect(mapStateToProps, mapDispatchToProps)
)(withSnackbar(Notifier));

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export function addNotification(type, message, isPersistentMessage = false, isMessageDict = true) {
    return {
        type: ADD_NOTIFICATION,
        notification: {
            key: new Date().getTime() + Math.random(),
            ...createNotification(type, message, isPersistentMessage, isMessageDict)
        }
    };
}

function createNotification(type, message, isPersistentMessage, isMessageDict) {
    return {
        message: message,
        isMessageDict: isMessageDict,
        options: {
            variant: type,
            persist: isPersistentMessage,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            }
        }
    };
}

export function addErrorNotification(message, isMessageDict = true) {
    return addNotification('error', message, true, isMessageDict);
}

export function addWarningNotification(message) {
    return addNotification('warning', message);
}

export function addSuccessNotification(message) {
    return addNotification('success', message);
}

export function addInfoNotification(message) {
    return addNotification('info', message);
}

export function removeNotification(key) {
    return {
        type: REMOVE_NOTIFICATION,
        key,
    };
}
import {ADD_NOTIFICATION, REMOVE_NOTIFICATION} from '../actions/notifications';

const defaultState = {notifications: []};

export default function notifications(state = defaultState, action) {
    switch (action.type) {
        case ADD_NOTIFICATION:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        ...action.notification,
                    },
                ],
            };

        case REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== action.key,
                ),
            };

        default:
            return state;
    }
};

import { INITIALIZE, CHANGE } from 'redux-form/lib/actionTypes';

const EMPTY_ERRORS = new Map();

const defaultInitialState = {
    form: '',
    open: false,
    edit: false,
    errors: EMPTY_ERRORS
};

export function createDialogReducer(dialogName = '', custom) {
    return function reducer(
        state = { ...defaultInitialState, form: dialogName },
        action
    ) {
        switch (action.type) {
            case `DIALOG_${dialogName}_OPEN`:
                return {
                    ...state,
                    open: true,
                    edit: action.edit,
                    errors: EMPTY_ERRORS
                };
            case `DIALOG_${dialogName}_CLOSE`:
            case `DIALOG_${dialogName}_SUBMITTED`:
                return {
                    ...state,
                    open: false,
                    errors: EMPTY_ERRORS
                };
            case `DIALOG_${dialogName}_BAD_REQUEST`:
                return {
                    ...state,
                    errors: action.errors
                };
            case `DIALOG_${dialogName}_URL`:
                return {
                    ...state,
                    urlVariables: action.urlVariables
                };
            case INITIALIZE:
            case CHANGE:
                const customValue = custom ? custom(action) : null
                return {
                    ...state,
                    ...customValue
                };
            default:
                return state;
        }
    };
}

export function createDialogFormReducer(dialogName, name, fields, custom) {
    return {
        [dialogName]: (state, action) => {
            switch (action.type) {
                case CHANGE:
                    const customValue = custom ? custom(action) : null
                    return {
                        ...state,
                        values: {
                            ...state.values,
                            ...customValue,
                            [name]: fields
                                .map(field => state.values[field] || '-')
                                .join('_')
                        }
                    };
                default:
                    return state;
            }
        }
    };
}

import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import routes from '../constants/routes';

export const ProtectedRoute = ({component: Component, auth, ...rest}) => (
    <Route
        {...rest}
        render={props => auth && auth.token ?
            (<Component {...props} />) :
            (<Redirect to={{pathname: routes.login, state: {from: props.location}}}/>)
        }
    />
);
import React from 'react';
import {connect} from 'react-redux';
import {CustomButton} from '../../components/CustomizedForms';
import {config} from '../../config';
import {getRestClient} from '../../utils/restClient';

function DownloadForm(props) {
    const {size = 'medium', color = 'primary', label, download, campaignId} = props;
    const callbackWrapper = () => download(campaignId != null ? '?campaignId=' + campaignId : '');
    return (
        <CustomButton component="button" size={size} color={color} callback={callbackWrapper}>
            {label}
        </CustomButton>
    );
}

function download(dispatch, urlSearchParam) {
    getRestClient(dispatch)
        .get(config.api.download + urlSearchParam)
        .then((response) => {
            saveDownloadedFile(response);
        });
}

function saveDownloadedFile(response) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.csv');
    document.body.appendChild(link);
    link.click();
}

const mapDispatchToProps = dispatch => ({
    download: (urlSearchParam) => download(dispatch, urlSearchParam)
});

export default connect(null, mapDispatchToProps)(DownloadForm);
import {connectRouter} from 'connected-react-router';
import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import auth from './auth';
import campaign from './campaign';
import createDataTableReducer from './dataTableReducer';
import {createDialogReducer, createDialogFormReducer} from './dialog';
import dictionary from './dictionary';
import menu from './menu';
import notifications from './notifications';
import upload from './upload';
import { channel } from '../containers/short-links/dropdowns';

function getSourceValues(value) {
    const { source } = channel.find(({ name }) => name === value) || { source: [] };
    return { source };
}

export default function createRootReducer(history: History) {
    return combineReducers({
        router: connectRouter(history),
        form: formReducer.plugin({
            ...createDialogFormReducer('CAMPAIGNS', 'name', ['platform', 'productOwner', 'salesArea', 'actionNumber', 'date']),
            ...createDialogFormReducer('SHORT_LINKS', 'shortLinkName', ['wmId', '$campaignName'], action => (action.meta.field === 'channel' ? { source: null } : null))
        }),
        menu,
        auth,
        upload,
        campaigns: createDataTableReducer('CAMPAIGNS'),
        campaign,
        campaignsDialog: createDialogReducer('CAMPAIGNS'),
        shortLinks: createDataTableReducer('SHORT_LINKS'),
        shortLinksDialog: createDialogReducer('SHORT_LINKS', action => {
            if (action.meta.field) {
                if (action.meta.field === 'channel') {
                    return getSourceValues(action.payload);
                }
            } else {
                if (action.payload && action.payload.channel) {
                    return getSourceValues(action.payload.channel);
                } else {
                    return { source: [] };
                }
            }
        }),
        notifications,
        dictionary
    });
}

import {config} from '../config';
import {getRestClient} from '../utils/restClient';

export const CAMPAIGN_FETCHED = 'CAMPAIGN_FETCHED';

export function getCampaign(name) {
    return (dispatch) => {
        return getRestClient(dispatch)
            .get(config.api.getCampaign + name)
            .then(response => dispatch(fetchSuccess(response.data)))
            .catch(error => {
                throw(error);
            });
    };
}

function fetchSuccess(payload) {
    return {
        type: CAMPAIGN_FETCHED,
        payload
    };
}
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import {ConnectedRouter} from 'connected-react-router';
import {SnackbarProvider} from 'notistack';
import React, {Component} from 'react';
import {I18nextProvider} from 'react-i18next';
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router';
import i18n from '../lang/i18n';
import type {Store} from '../reducers/types';
import {theme} from '../utils/themeBuilder';
import DefaultContainer from "./DefaultContainer";
import LoginContainer from "./login/LoginContainer";
import Notifier from "../components/Notifier";
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';

type Props = {
    store: Store,
    history: {}
};

export default class App extends Component<Props> {
    render() {
        const {store, history} = this.props;
        return (
            <React.Fragment>
                <CssBaseline/>
                <I18nextProvider i18n={i18n}>
                    <MuiThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <SnackbarProvider maxSnack={15}>
                                <Provider store={store}>
                                    <Notifier/>
                                    <ConnectedRouter history={history}>
                                        <Switch>
                                            <Route exact path="/(login)" component={LoginContainer}/>
                                            <Route component={DefaultContainer}/>
                                        </Switch>
                                    </ConnectedRouter>
                                </Provider>
                            </SnackbarProvider>
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                </I18nextProvider>
            </React.Fragment>
        );
    }
}

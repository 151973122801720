import {withStyles} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import {withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import createDataTableActions from '../../actions/dataTableActions';
import createDialogActions from '../../actions/dialog';
import * as UploadActions from '../../actions/upload';
import {CustomButton} from '../../components/CustomizedForms';
import DataTable from '../../components/DataTable';
import PageHeader from '../../components/PageHeader';
import {config} from '../../config';
import defaultPage from '../../constants/defaults';
import routes from '../../constants/routes';
import {getColumnsDefinition} from '../campaigns/CampaignsPage';

const styles = () => ({
    subtitle: {
        marginTop: '3em'
    }
});

class WelcomePage extends React.Component {

    constructor(props) {
        super(props);
        this.setupColumns();

        this.onRowClicked = this.onRowClicked.bind(this);
        this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.uploadAndRefresh = this.uploadAndRefresh.bind(this);
    }

    setupColumns() {
        const {t} = this.props;
        this.columns = getColumnsDefinition(t);
    }

    componentDidMount() {
        this.props.init();
    }

    onRowClicked(row) {
        this.props.dialogOpen(row, true);
    }

    onRowDoubleClicked(row) {
        const {history} = this.props;
        history.push(routes.campaigns + '/' + row.cid);
    }

    onSearch(payload) {
        const {setFilter} = this.props;
        setFilter({
            [payload.searchRadio]: payload.searchString
        });
    }

    uploadAndRefresh(data) {
        const {upload, setPage} = this.props;
        upload(data).then(() => setPage(defaultPage));
    }

    render() {
        const {t, campaigns, setPageSize, setPage, setOrder, setOrderBy, history, classes} = this.props;

        const buttons = [
            <CustomButton component="button" callback={() => history.push(routes['add-campaigns'])}>
                {t('buttons.add')}
            </CustomButton>,
            <CustomButton component="button">
                {t('buttons.edit')}
            </CustomButton>];

        return (
            <React.Fragment>
                <PageHeader title={t('title')} leftButtons={buttons}/>
                <Typography component="h2" variant="h5" gutterBottom
                            className={classes.subtitle}>{t('subtitle')}</Typography>
                <DataTable columns={this.columns}
                           data={campaigns.data}
                           order={campaigns.sort.order}
                           setOrder={setOrder}
                           orderBy={campaigns.sort.orderBy}
                           setOrderBy={setOrderBy}
                           page={campaigns.page.number}
                           setPage={setPage}
                           pageSize={campaigns.page.size}
                           setPageSize={setPageSize}
                           totalElements={campaigns.page.totalElements}
                           rowClick={this.onRowClicked}
                           rowDoubleClick={this.onRowDoubleClicked}
                />
                <CustomButton component="button" size="small" color="default"
                              callback={() => history.push(routes['campaigns'])}>
                    {t('buttons.campaigns')}
                </CustomButton>
            </React.Fragment>
        );
    }
}

WelcomePage.propTypes = {
    t: PropTypes.func.isRequired,
    campaigns: PropTypes.object.isRequired,
    setPageSize: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    setOrder: PropTypes.func.isRequired,
    setOrderBy: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired
};


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ...createDataTableActions('CAMPAIGNS', 'campaigns', config.api.allCampaigns),
        ...createDialogActions('CAMPAIGNS', 'campaignsDialog', config.api.allCampaigns),
        ...UploadActions
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        campaigns: state.campaigns,
        uploadStatus: state.upload
    };
}

export default compose(
    withNamespaces('welcome'),
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(WelcomePage);

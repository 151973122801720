import {withStyles, withTheme} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import {withNamespaces} from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators, compose} from 'redux';
import * as UploadActions from '../../actions/upload';
import PageHeader from '../../components/PageHeader';
import DownloadForm from './DownloadForm';
import UploadForm from './UploadForm';


const styles = theme => {
    return ({
        sectionSeparator: {
            marginTop: theme.custom.section.marginTop
        },
        progress: {
            padding: '0.5em'
        },
        buttonContainer: {
            display: 'grid',
            width: '20em'
        },
    });
};

function UpDownloadPage(props) {
    const {t, theme, classes, upload, status} = props;
    return (
        <React.Fragment>
            <PageHeader title={t('title')}/>
            <Section theme={theme} classes={classes} title={t('upload.title')}>
                <UploadForm onSubmit={upload} status={status} label={t('upload.button')}/>
            </Section>

            <Section theme={theme} classes={classes} title={t('download.title')}>
                <DownloadForm {...props} label={t('download.button')}/>
            </Section>
        </React.Fragment>
    );
}

UpDownloadPage.propTypes = {
    t: PropTypes.func.isRequired,
    upload: PropTypes.func.isRequired,
    status: PropTypes.object.isRequired
};


function mapDispatchToProps(dispatch) {
    return bindActionCreators(UploadActions, dispatch);
}

function mapStateToProps(state) {
    return {
        status: state.upload
    };
}

export default compose(
    withNamespaces('up_download'),
    withStyles(styles),
    withTheme(),
    connect(mapStateToProps, mapDispatchToProps)
)(UpDownloadPage);

function Section(props) {
    const {title, children, theme, classes} = props;
    return (
        <Grid container spacing={theme.custom.grid.spacing}>
            <Grid item xs={12} className={classes.sectionSeparator}>
                <Typography component="h2" variant="h5" gutterBottom>{title}</Typography>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.buttonContainer}>
                    {children}
                </div>
            </Grid>
        </Grid>
    );
}

import campaigns from './campaigns';
import common from './common';
import data_table from './data_table';
import dictionaries from './dictionaries';
import login from './login';
import menu from './menu';
import notifications from './notifications';
import short_links from './short_links';
import up_download from './up_download';
import admin from './admin';
import welcome from './welcome';

export default {
    menu,
    up_download,
    login,
    notifications,
    campaigns,
    data_table,
    dictionaries,
    common,
    short_links,
    admin,
    welcome
};